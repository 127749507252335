.formList {
  margin-bottom: 0.2rem;
}
.formList .hd {
  color: #333333;
  font-size: 0.28rem;
  font-weight: bold;
}
.formList .bd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.1rem;
  border-bottom: 1px solid #EDEDED;
}
.formList .inp {
  color: #373737;
  font-size: 0.28rem;
  height: 0.6rem;
  display: block;
  width: 100%;
  flex: 1;
  border: 0;
  outline: 0;
}
.formList .button {
  line-height: 0.48rem;
  padding: 0 0.15rem;
  border-radius: 0.04rem;
  border: 1px solid #90C4FF;
}
